<template>
  <div class="home">
    <div class="header">
      <i class="icon el-icon-s-unfold"></i>
      <span class="title">开发测试</span>
    </div>
    <div class="content">
      <span class="text">WELCOME HOME</span>
      <!-- <div ref="container" class="monaco-editor"></div> -->
    </div>
    <div class="footer">
      <a class="beian" href="https://beian.miit.gov.cn" target="_blank" rel="noopener noreferrer">湘ICP备2023002280号-1</a>
    </div>
  </div>
</template>

<script>

export default {
  name: 'HelloWorld',
  data() {
    return {
      // 主要配置
      defaultOpts: {
        // 编辑器的值
        value: 'const json = {}',
        // 编辑器主题：vs, hc-black, or vs-dark，更多选择详见官网
        theme: 'vs',
        // 右侧不显示编辑器预览框
        roundedSelection: true,
        // 自动缩进
        autoIndent: true,
        // 是否只读
        readOnly: false,
        // 语言类型java,c,php更多选择详见官网
        language: 'javascript',
      },
      // 编辑器对象
      monacoEditor: {}
    }
  },
  mounted() {
    // this.init();
  },
  methods: {
    init() {
      // 初始化container的内容，销毁之前生成的编辑器
      this.$refs.container.innerHTML = ''
      // 生成编辑器配置
      let editorOptions = Object.assign(this.defaultOpts, this.opts)
      // 生成编辑器对象
      this.monacoEditor = window.monaco.editor.create(this.$refs.container, editorOptions)
      // 编辑器内容发生改变时触发
      this.monacoEditor.onDidChangeModelContent(() => {
        this.$emit('change', this.monacoEditor.getValue())
      })
    },
    // 手动编辑器中的内容
    getValue() {
      this.$message.info(this.$refs.monaco.getVal())
    },
  }
}
</script>

<style scoped>
.home {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 1);
  display: flex;
  flex-direction: column;
}
.header {
  height: 60px;
  padding-left: 150px;
  display: flex;
  align-items: center;
}
.icon {
  color: red;
  font-size: 34px;
}
.title {
  margin-left: 4px;
  color: #fff;
  font-size: 28px;
  font-weight: bold;
}
.content {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.text {
  font-size: 50px;
  font-weight: bold;
  color: rgb(255, 255, 255);
}
.footer {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.beian {
  color: rgb(255, 255, 255);
  text-decoration: none;
}

/* monaco-editor */
.monaco-editor {
  width: 600px;
  height: 400px;
}
</style>
